@font-face {
  font-family: "Summer Show";
  src: url("../assets/fonts/Summer-Show.ttf") format("truetype");
}

* {
  cursor: url("../assets/images/Cursor.png"), auto;
}

body {
  margin: 0;
  overflow: hidden;
}

iframe{
  border: hidden;
}

button, .pointer {
  cursor: url("../assets/images/Pointer.png"), auto !important;
}

.typer {
  cursor: url("../assets/images/Typer.png"), auto;
}

.Toastify__toast {
  background-color: #FBF6D8;
  filter: drop-shadow(-0.5vh 0.66vh 1vh black);
  border-radius: 0.66vh;
  box-shadow: 0 0.16vh 1.66vh 0 rgba(0,0,0,.1), 0 0.33vh 2.5vh 0 rgba(0,0,0,.05);
  font-size: 2.5vh;
  min-height: 10.66vh;
  padding: 1.33vh;
  margin-bottom: 2.5vh;
}

.Toastify__toast-body {
  color: #7C382D;
  font-family: "Summer Show";
  padding: 1vh;
}

.Toastify__toast-container{
  left: 73.5vw;
  bottom: 1.25vw;
  width: 25vw;
  padding: 0.66vh;
}

.Toastify__toast-icon {
  width: 1.57vw;
}

.Toastify__progress-bar{
  height: 0.84vh;
}

.MuiPickersMonth-monthSelected, .MuiPickersYear-yearSelected {
  color: #207067 !important;
}